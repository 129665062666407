<template>
  <Modal v-model="inventoryDetailsModal" :title="inventoryDetailsTitle" width="700"
    :transfer="false" @on-visible-change="inventoryDetailsChange">
    <Table
      highlight-row
      border
      max-height="500"
      :loading="tableLoading"
      :columns="tableColumns"
      :data="tableData">
    </Table>
    <template #footer>
      <Button type="primary" @click="inventoryDetailsModal = false">{{ $t('key1000204') }}</Button>
    </template>
  </Modal>
</template>

<script>
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import api from '@/api/';

export default {
  name: "inventoryDetailsModal",
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      inventoryDetailsModal: false,
      tableColumns: [
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000205'),
          key: 'skuInfo',
          align: 'left',
          minWidth: 350,
          render(h, params) {
            let skuImgPath = params.row._productInfo.primaryImage;
            let spuImgPath = params.row._productInfo._spuInfo.primaryImage;
            let imgPath = skuImgPath ? skuImgPath : spuImgPath;
            let sku = '';
            let productGoodsSpecifications = [];
            if (v.inventoryType === 'supplier_supplierInventory') {
              sku = params.row._productInfo.sku;
            }
            if (['yms_ymsInventory', 'supplier_ymsInventory'].includes(v.inventoryType)) {
              sku = params.row._productInfo.ymsSku;
            }
            if (v.inventoryType === 'yms_supplierInventory') {
              sku = params.row._productInfo.supplierSku;
            }
            if (['yms_ymsInventory', 'yms_supplierInventory'].includes(v.inventoryType)) {
              productGoodsSpecifications = params.row._productInfo.productGoodsSpecificationList || [];
            } else {
              productGoodsSpecifications = params.row._productInfo.productGoodsSpecifications || [];
            }
            let spec = productGoodsSpecifications.map(i => i.name + '：' + i.value).join('、');
            return h('div', {class: 'flex mt5 mb5'}, [
              v.tableImg(h, params, null, imgPath),
              h('div', {class: 'ml5'}, [
                h('div', {class: 'flex align-items-start mb5'}, [
                  h('p', {
                    style: {
                      width: '70px',
                      textAlign: 'right'
                    }
                  }, alias1bddc4d174174d47a6dfb0437dace856.t('key1000085')),
                  h('p', {
                    style: {
                      flex: 1
                    }
                  }, params.row._productInfo._spuInfo.name),
                ]),
                h('div', {class: 'flex align-items-start mb5'}, [
                  h('p', {
                    style: {
                      width: '70px',
                      textAlign: 'right'
                    }
                  }, 'SKU：'),
                  h('p', {
                    style: {
                      flex: 1
                    }
                  }, sku),
                ]),
                h('div', {class: 'flex align-items-start mb5'}, [
                  h('p', {
                    style: {
                      width: '70px',
                      textAlign: 'right'
                    }
                  }, alias1bddc4d174174d47a6dfb0437dace856.t('key1000206')),
                  h('p', {
                    style: {
                      flex: 1
                    },
                    class: 'color-green'
                  }, spec),
                ]),
              ])
            ]);
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000207'),
          key: 'allottedNumber',
          align: 'left',
          minWidth: 120,
          render(h, params) {
            let value = 0;
            if (['supplier_supplierInventory', 'supplier_ymsInventory', 'yms_supplierInventory'].includes(v.inventoryType)) {
              value = params.row.allottedNumber || 0;
            } else {
              value = params.row.ymsAllottedNumber || 0;
            }
            return h('span', value);
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000208'),
          key: 'availableNumber',
          minWidth: 120,
          align: 'left',
          render(h, params) {
            let currentIndex = params.index;
            let isEdit = params.row.$isEdit;
            let value = params.row.availableNumber;
            let ymsAvailableNumber = params.row.ymsAvailableNumber;
            if (v.inventoryType === 'supplier_supplierInventory') {
              let opts = '';
              if (isEdit) {
                let talgValue = '';
                opts = h('div', {
                  style: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }
                }, [
                  h('Input', {
                    style: {
                      width: '100px',
                      height: '32px'
                    },
                    props: {
                      value: value,
                      autofocus: true
                    },
                    attrs: {
                      class: 'availableInput_' + currentIndex,
                    },
                    on: {
                      input: val => {
                        talgValue = val;
                      },
                      'on-enter': () => {
                        let reg = v.$regular.minPositiveInteger;
                        if (reg.test(Number(talgValue))) {
                          if (talgValue) {
                            v.availableQuantity(talgValue, params.row.productGoodsId, params, true);
                          } else {
                            v.availableQuantity(value, params.row.productGoodsId, params, true);
                          }
                        } else {
                          v.$Message.error(alias1bddc4d174174d47a6dfb0437dace856.t('key1000209'));
                        }
                      },
                      'on-blur': () => {
                        setTimeout(() => {
                          let reg = v.$regular.minPositiveInteger;
                          if (reg.test(Number(talgValue))) {
                            if (talgValue) {
                              if (talgValue !== value && !v.enterTalg) {
                                v.availableQuantity(talgValue, params.row.productGoodsId, params, false);
                              }
                            } else {
                              params.row.availableNumber = value;
                              params.row.$isEdit = false;
                              v.enterTalg = false;
                              v.$forceUpdate();
                            }
                          } else {
                            v.$Message.error(alias1bddc4d174174d47a6dfb0437dace856.t('key1000209'));
                          }
                        }, 200);
                      },
                    }
                  }),
                  h('Icon', {
                    props: {
                      type: 'md-close-circle',
                      color: '#f00',
                      size: 20
                    },
                    style: {
                      marginLeft: '5px'
                    },
                    class: 'cursor',
                    on: {
                      click: () => {
                        v.$set(params.row, '$isEdit', !isEdit);
                        v.enterTalg = true;
                      }
                    }
                  })
                ]);
              } else {
                opts = h('a', {
                  style: {
                    color: '#2586ED',
                    fontSize: '13px'
                  },
                  on: {
                    click: () => {
                      setTimeout(() => {
                        v.$nextTick(() => {
                          document.getElementsByClassName('availableInput_' + currentIndex)[0].childNodes[6].focus();
                        });
                      }, 300);
                      v.$set(params.row, '$isEdit', !isEdit);
                      v.enterTalg = false;
                    }
                  }
                }, value);
              }
              return opts;
            }
            if (v.inventoryType === 'supplier_ymsInventory') {
              return h('span', value);
            }
            if (v.inventoryType === 'yms_supplierInventory') {
              return h('span', value);
            }
            if (v.inventoryType === 'yms_ymsInventory') {
              return h('span', ymsAvailableNumber);
            }
          }
        }
      ],
      tableData: [],
      inventoryDetailsTitle: '',
      inventoryType: null,
      enterTalg: false,
    }
  },
  methods: {
    // 初始化数据
    initInventoryDetails(type, row) {
      let obj = {
        // 供应商系统---供应商库存
        supplier_supplierInventory: {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000210'),
          url: api.post_productInfo_queryGoodsInventoryById,
          query: {
            productGoodsIds: [],
            productId: row.productId,
            receiptBatchNo: null,
            warehouseId: this.getWarehouseId(),
            warehouseLocationId: null
          }
        },
        // 供应商系统---中心仓库存
        supplier_ymsInventory: {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000211'),
          url: api.post_ymsInventory_api_queryGoodsForSupplierProductIds,
          query: [row.productId]
        },
        // 运营系统---供应商库存
        yms_supplierInventory: {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000210'),
          url: api.get_productGoods_queryInventoryByYmsProductId + `?ymsProductId=${row.ymsProductId}&type=${2}`
        },
        // 运营系统---中心仓库存
        yms_ymsInventory: {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000211'),
          url: api.get_productGoods_queryInventoryByYmsProductId + `?ymsProductId=${row.ymsProductId}&type=${1}`
        }
      }
      this.inventoryType = type;
      this.inventoryDetailsTitle = obj[type].title;
      let url = obj[type].url;
      let query = obj[type].query;
      if (['supplier_supplierInventory', 'supplier_ymsInventory'].includes(type)) {
        this.axios.post(url, query, {loading: true, loadingText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000212')}).then(response => {
          this.tableLoading = false;
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            if (data.length > 0) {
              let productGoodsIds = [];
              data.map((item) => {
                item['$isEdit'] = false;
                if (type === 'supplier_ymsInventory') {
                  item.productGoodsId = item.supplierProductGoodsId;
                }
                productGoodsIds.push(item.productGoodsId);
              });
              let list = this.uniqueFunc(productGoodsIds);
              if (list.length > 0) {
                (async () => {
                  this.tableData = await this.getProductInfoByIds(productGoodsIds, data,
                    false, false, false, api.post_productInfo_queryByProductGoodsIds, 'supplier');
                })();
              }
            }
            this.inventoryDetailsModal = true;
          }
        });
      } else {
        this.axios.get(url, {loading: true, loadingText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000212')}).then(response => {
          this.tableLoading = false;
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            if (data.length > 0) {
              let ymsProductGoodsIds = [];
              data.map((item) => {
                ymsProductGoodsIds.push(item.ymsProductGoodsId);
              });
              let list = this.uniqueFunc(ymsProductGoodsIds);
              if (list.length > 0) {
                (async () => {
                  this.tableData = await this.getProductInfoByIds(ymsProductGoodsIds, data);
                })();
              }
            }
            this.inventoryDetailsModal = true;
          }
        });
      }
    },
    // 编辑可用数量
    availableQuantity(availableNumber, productGoodsId, params, value) {
      let v = this;
      let query = {
        availableNumber: availableNumber,
        productGoodsId: productGoodsId,
        warehouseId: v.getWarehouseId()
      };
      v.axios.put(api.put_productInfo_updateSkuInventory, query).then(res => {
        if (res.data.code === 0) {
          params.row.availableNumber = availableNumber;
          params.row.$isEdit = false;
          v.$Message.success(alias1bddc4d174174d47a6dfb0437dace856.t('key1000084'));
          v.enterTalg = value;
          v.$emit('updateData', true);
          if (value) {
            setTimeout(() => {
              v.enterTalg = !value;
            }, 1500);
          }
        }
      });
    },
    // 监听弹窗
    inventoryDetailsChange(value) {
      let v = this;
      if (!value) {
        v.inventoryDetailsTitle = '';
        v.inventoryType = null;
        v.tableData = [];
      }
    }
  }
}
</script>

<style scoped>

</style>